import {
  createTRPCReact,
  inferReactQueryProcedureOptions,
} from '@trpc/react-query';
import {
  TRPCClientErrorLike,
  createTRPCProxyClient,
  httpBatchLink,
} from '@trpc/client';
import superjson from 'superjson';
import {inferRouterInputs, inferRouterOutputs} from '@trpc/server';
import type {AppRouter} from '@local/backend';
import {addBreadcrumb, captureException, startSpan} from '@sentry/react';
import {BACKEND_CONFIG} from '../../config/backend';

export type TrpcError = TRPCClientErrorLike<AppRouter>;
export type TrpcReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type TrpcRouterInputs = inferRouterInputs<AppRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${BACKEND_CONFIG.URL}/trpc`,
      fetch(url, options) {
        return startSpan(
          {
            name: url instanceof URL ? url.href : url.toString(),
            op: 'http.client',
          },
          async (span) => {
            addBreadcrumb({
              level: 'info',
              message: `making request to ${url}`,
              data: options,
            });

            const res = await fetch(url, {
              ...options,
              credentials: 'include',
            });

            span?.setAttribute('http.status_code', res?.status);

            if (!res.ok) {
              captureException(
                new Error(
                  `HTTP Request Failed: ${res.status} ${res.statusText}`
                )
              );
            }

            return res;
          }
        );
      },
    }),
  ],
  transformer: superjson,
});

export const trpcRootQueryClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${BACKEND_CONFIG.URL}/trpc`,
      fetch(url, options) {
        return startSpan(
          {
            name: url instanceof URL ? url.href : url.toString(),
            op: 'http.client',
          },
          async (span) => {
            addBreadcrumb({
              level: 'info',
              message: `making request to ${url}`,
              data: options,
            });

            const res = await fetch(url, {
              ...options,
              credentials: 'include',
            });

            span?.setAttribute('http.status_code', res?.status);

            if (!res.ok) {
              captureException(
                new Error(
                  `HTTP Request Failed: ${res.status} ${res.statusText}`
                )
              );
            }

            return res;
          }
        );
      },
    }),
  ],
  transformer: superjson,
});

// merchants
export const useGetMerchant = trpc.merchants.get.useQuery;

// multi-card
export const useMultiCardPayment = trpc.multiCard.get.useQuery;
export const useCancelMultiCard = trpc.multiCard.cancel.useMutation;
export const usePayIntoMultiCardPayment = trpc.multiCard.payInto.useMutation;
export const useFindMultiCardPaymentGateway =
  trpc.connections.getMultiCardGateway.useQuery;
export const useCancelPaymentOfMultiCard =
  trpc.payments.cancelPaymentOfMultiCard.useMutation;
export const useGetPaymentsOfMultiCard =
  trpc.payments.getMultiCardPayments.useQuery;
export const useGetPaymentOfMultiCard =
  trpc.payments.getMultiCardPayment.useQuery;
export const useGetCustomerOfMultiCard =
  trpc.customers.getMultiCardCustomer.useQuery;

// payments
export const useGetPaymentByInternalReferenceId =
  trpc.payments.getPaymentByInternalReferenceId.useQuery;
export const useCreatePaymentMutation = trpc.payments.pay.useMutation;
export const useCreatePaymentSession =
  trpc.payments.createPaymentSession.useMutation;

// group payments
export const useGetPaymentsFromGroup =
  trpc.groupPayments.useGetPaymentsFromGroup.useQuery;
export const useCancelGroupPayment = trpc.groupPayments.cancel.useMutation;
export const useInviteToGroupPayment = trpc.groupPayments.invite.useMutation;
export const useRemoveFromGroupPayment = trpc.groupPayments.remove.useMutation;

// intercom chat
export const useIntercomHash = trpc.intercom.generateHash.useMutation;
