interface FeatureFlag {
  key: string;
  variants: {
    default: false;
    [key: string]: string | boolean;
  };
}

export const featureFlags = {
  enableSupport: {
    key: 'enable-support',
    variants: {
      default: false,
    },
  },
  joinFormConversion: {
    key: 'group-email-phone-invite-conversion',
    variants: {
      default: false,
      both: 'control',
      phoneOnly: 'phone-only',
      emailOnly: 'email-only',
      none: 'none',
    },
  },
  splitByItem: {
    key: 'split-by-item-conversion',
    variants: {
      default: false,
      control: 'control',
      mergedFlow: 'merged-flow',
      avatarFlow: 'avatar-flow',
    },
  },
  noDashboard: {
    key: 'no-dashboard',
    variants: {
      default: false,
      control: 'control',
      noDashboard: 'sbi-no-dashboard',
    },
  },
  assignMembersToItems: {
    key: 'assign-members-to-items',
    variants: {
      default: false,
      control: 'control',
      assignMembersToItems: 'assign-members-to-items',
    },
  },
  howItWorks: {
    key: 'no-how-it-works',
    variants: {
      default: false,
      control: 'control',
      noHowItWorks: 'sbi-no-how-it-works',
    },
  },
  hideHowItWorks: {
    key: 'hide-how-it-works',
    variants: {
      default: false,
      control: 'control',
      hideHowItWorks: 'hidden',
    },
  },
  skipVerifyAndAssign: {
    key: 'skip-verify-and-assign',
    variants: {
      default: false,
      control: 'control',
      skipVerifyAndAssign: 'skip-verify-and-assign',
    },
  },
  skipPay: {
    key: 'skip-pay-modal',
    variants: {
      default: false,
      control: 'control',
      skipPay: 'skip-pay-modal',
    },
  },
  modernDashboard: {
    key: 'modern-dashboard',
    variants: {
      default: false,
      control: 'control',
      modernDashboard: 'modern-dashboard',
    },
  },
  multiCardVariants: {
    key: 'multi-card-variants',
    variants: {
      default: false,
      control: 'control',
      singlePage: 'single-page',
      perPage: 'per-page',
      multiCardVariant3: 'multicard-variant-3',
    },
  },
} satisfies Record<string, FeatureFlag>;
