import React, {FC, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {JoinerGroupModalStep} from '@local/frontend/libs/modals/ModalName';
import {useGetGroupCustomerPayment} from '@local/frontend/hooks/payments/useGetGroupCustomerPayment';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';
import {useCurrentCustomerId} from '../../../../hooks/data/ids';
import EqualMemberList from './EqualMemberList';
import JoinGroupForm from './JoinGroupForm';
import JoinGroupOverview from './JoinGroupOverview/JoinGroupOverview';
import ReInviteGroupMember from './ReInviteGroupMember';
import GroupMemberAlreadyPaidMessage from './GroupMemberAlreadyPaidMessage';
import GroupPaymentFooter from '../../GroupPaymentFooter';

interface JoinGroupModalProps {
  groupPayment: GroupPaymentRecord;
}

const JoinGroupModal: FC<React.PropsWithChildren<JoinGroupModalProps>> = ({
  groupPayment,
}) => {
  const currentCustomerId = useCurrentCustomerId();
  const {customerPayment: payment} = useGetGroupCustomerPayment(
    groupPayment.id,
    currentCustomerId ?? ''
  );

  // note: react-query v4 doesn't support undefined queryFn return values anymore
  const {data: selectedCustomer} = useQuery<Customer | null>(
    ['selectedCustomer'],
    () => null,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [modalStep, setModalStep] = useState(JoinerGroupModalStep.INVITED_LIST);

  useEffect(() => {
    // check if a customerId was provided and if they have a payment
    if (
      !!currentCustomerId &&
      payment &&
      ['PENDING', 'APPROVED', 'COMPLETED'].includes(payment.status)
    ) {
      setModalStep(JoinerGroupModalStep.ALREADY_PAID);
    }
  }, [currentCustomerId, payment]);

  const renderModalContent = (joinerGroupModalStep: JoinerGroupModalStep) => {
    switch (joinerGroupModalStep) {
      case JoinerGroupModalStep.JOINER_FORM:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JoinGroupForm
                setModalStep={setModalStep}
                groupPayment={groupPayment}
                selectedCustomer={selectedCustomer as Customer | null}
              />
            </Grid>
            <Grid item xs={12}>
              <GroupPaymentFooter
                onGoBack={() =>
                  setModalStep(JoinerGroupModalStep.JOINER_OVERVIEW)
                }
              />
            </Grid>
          </Grid>
        );
      case JoinerGroupModalStep.INVITED_LIST:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EqualMemberList
                setModalStep={setModalStep}
                groupPayment={groupPayment}
                selectedCustomer={selectedCustomer as Customer | null}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <GroupPaymentFooter />
            </Grid>
          </Grid>
        );
      case JoinerGroupModalStep.REINVITE:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReInviteGroupMember groupPayment={groupPayment} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <GroupPaymentFooter
                onGoBack={() => setModalStep(JoinerGroupModalStep.JOINER_FORM)}
              />
            </Grid>
          </Grid>
        );
      case JoinerGroupModalStep.ALREADY_PAID:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GroupMemberAlreadyPaidMessage groupPayment={groupPayment} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <GroupPaymentFooter />
            </Grid>
          </Grid>
        );
      case JoinerGroupModalStep.JOINER_OVERVIEW:
      default:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JoinGroupOverview
                setModalStep={setModalStep}
                groupPayment={groupPayment}
                selectedCustomer={selectedCustomer as Customer | null}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <GroupPaymentFooter
                onGoBack={
                  groupPayment.invited && groupPayment.invited.length > 0
                    ? () => {
                        setModalStep(JoinerGroupModalStep.INVITED_LIST);
                      }
                    : undefined
                }
              />
            </Grid>
          </Grid>
        );
    }
  };

  return renderModalContent(modalStep);
};

export default JoinGroupModal;
