import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import useGetAllPaymentsFromGroup from './useGetAllPaymentsFromGroup';

export type UseGetGroupCustomerPaymentReturnType = {
  customerPayment: PaymentRecord | undefined;
  hasPaid: boolean;
  isPaymentLoading: boolean;
};

// return the customer's payment record, whether it was made by themselves or from another group member paying on the customer's behalf
const getLatestSuccessfulPaymentForCustomerInGroup = (
  customerPaymentRecord: PaymentRecord | undefined,
  paidOnBehalfPayment: PaymentRecord | undefined
): PaymentRecord | undefined => {
  const isPaidOnBehalfRecordValid =
    paidOnBehalfPayment &&
    ['APPROVED', 'COMPLETED', 'PENDING'].includes(paidOnBehalfPayment.status);

  // check if customer has their own payment record
  if (customerPaymentRecord) {
    // if their payment is valid, then we can return the customers payment.
    if (
      ['APPROVED', 'COMPLETED', 'PENDING'].includes(
        customerPaymentRecord.status
      )
    ) {
      return customerPaymentRecord;
    }

    // check if there is a valid payment made on behalf of that customer
    if (isPaidOnBehalfRecordValid) {
      return paidOnBehalfPayment;
    }

    // always fallback to the customer's own payment record as it exists, but is usually in a cancelled or failed state.
    return customerPaymentRecord;
  }

  // otherwise if the customer does not have their own payment, check if there is a valid payment made on behalf of the customer
  if (isPaidOnBehalfRecordValid) {
    return paidOnBehalfPayment;
  }

  // return no payment, any paidOnBehalf cancelled/failed payment can confuse the customer when viewing, hence we don't return it
  return undefined;
};

export const useGetGroupCustomerPayment = (
  groupPaymentId: string,
  customerId: string
): UseGetGroupCustomerPaymentReturnType => {
  const {payments, isPaymentsLoading} =
    useGetAllPaymentsFromGroup(groupPaymentId);

  const customerOwnPayment = payments?.find(
    (payment) =>
      !payment.onBehalfOf && // !!IMPORTANT to ensure we only find the payment for the customer and not any payment they may have made on behalf of someone else
      !!payment.customerId &&
      payment.customerId === customerId
  );

  const customerPaymentMadeBySomeoneElse = payments?.find(
    (payment) => !!payment.customerId && payment.onBehalfOf === customerId
  );

  // get the correct customer payment record to return
  const customerPayment = getLatestSuccessfulPaymentForCustomerInGroup(
    customerOwnPayment,
    customerPaymentMadeBySomeoneElse
  );

  return {
    customerPayment,
    hasPaid:
      !!customerPayment &&
      ['APPROVED', 'COMPLETED', 'PENDING'].includes(customerPayment.status),
    isPaymentLoading: isPaymentsLoading,
  };
};
