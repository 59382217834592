import {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {PaymentItem} from '@local/backend/db/payments';
import useMerchantId from '../useMerchantId';
import {useGetPaymentsFromGroup} from '../../libs/trpc/trpc';

const convertPaymentEntityToPaymentRecord = (
  paymentEntity: PaymentItem
): PaymentRecord => ({
  ...paymentEntity,
  createdAt: paymentEntity.createdAt.toString(),
  updatedAt: paymentEntity.updatedAt.toString(),
});

export type UseAllPaymentsReturnType = {
  isPaymentsLoading: boolean;
  payments: PaymentRecord[] | undefined;
};

// retrieves all payments made into a group
const useGetAllPaymentsFromGroup = (
  groupPaymentId: string
): UseAllPaymentsReturnType => {
  const merchantId = useMerchantId();

  const {data: paymentEntities, isInitialLoading: isPaymentsLoading} =
    useGetPaymentsFromGroup(
      {
        merchantId: merchantId ?? '',
        groupPaymentId,
      },
      {enabled: !!merchantId}
    );

  if (!merchantId || !paymentEntities || paymentEntities.length === 0) {
    return {
      isPaymentsLoading: false,
      payments: undefined,
    };
  }

  if (isPaymentsLoading) {
    return {
      payments: undefined,
      isPaymentsLoading: true,
    };
  }

  const payments = paymentEntities
    .sort((a, b) => b.createdAt - a.createdAt) // Sort payments by createdAt in descending order, so the most recent payment is always first
    .map(convertPaymentEntityToPaymentRecord); // convert electroDB Payment entity to API PaymentRecord

  return {
    payments,
    isPaymentsLoading,
  };
};

export default useGetAllPaymentsFromGroup;
