import {PaymentStatus} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';

export const getPaymentStatusColor = (
  status: PaymentStatus
): {backgroundColor: string; textColor: string} => {
  switch (status) {
    case PaymentStatus.COMPLETED:
    case PaymentStatus.APPROVED:
      return {backgroundColor: '#EDFFEE', textColor: '#4EB052'};
    case PaymentStatus.PENDING:
      return {backgroundColor: '#FFF7E2', textColor: '#FF9900'};
    case PaymentStatus.FAILED:
    case PaymentStatus.CANCELLED:
    default:
      return {backgroundColor: '#FFE4E2', textColor: '#FF3D00'};
  }
};
