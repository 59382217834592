import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {JoinerGroupModalStep} from '@local/frontend/libs/modals/ModalName';
import useGetAllPaymentsFromGroup from '@local/frontend/hooks/payments/useGetAllPaymentsFromGroup';
import {sortCustomersByCreatedAt} from '../../../../util';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';
import {useInitializeCustomers} from '../../../../hooks/queries/customers';
import EqualMemberListItem from './EqualMemberListItem';

interface EqualMemberListProps {
  groupPayment: GroupPaymentRecord;
  setModalStep: Dispatch<SetStateAction<JoinerGroupModalStep>>;
  selectedCustomer: Customer | null;
}

const EqualMemberList: FC<React.PropsWithChildren<EqualMemberListProps>> = ({
  groupPayment,
  setModalStep,
  selectedCustomer,
}) => {
  const {t} = useTranslation(['modals', 'glossary']);
  const queryClient = useQueryClient();
  // get all added members from the cache

  const addedCustomersInCache = useInitializeCustomers(groupPayment.invited);
  const {payments} = useGetAllPaymentsFromGroup(groupPayment.id);

  // store if any customer queries are loading
  const areCustomerQueriesLoading = addedCustomersInCache.some(
    (customerQuery) => customerQuery.isLoading
  );

  // store all added customer's customer data
  const addedCustomersWithoutPayment = addedCustomersInCache
    .map((addedCustomerQuery) => {
      const customer = addedCustomerQuery.data;
      if (!customer) {
        return undefined;
      }

      const payment = payments?.find(
        (p) => !!p.customerId && p.customerId === customer.id && !p.onBehalfOf
      );

      if (!payment) {
        return customer;
      }

      // check if customer has a pending, approved or completed payment
      if (['PENDING', 'APPROVED', 'COMPLETED'].includes(payment.status)) {
        return undefined;
      }

      return customer;
    })
    .filter((maybeCustomer): maybeCustomer is Customer => !!maybeCustomer);

  // sort customers
  const addedSortedCustomers: Customer[] = sortCustomersByCreatedAt(
    addedCustomersWithoutPayment
  );

  const numberOfCustomersJoinedInGroup = groupPayment.memberIds.length;

  // the number of spaces in an equal or fixed price group
  const numberOfAvailableSpacesLeft =
    groupPayment.splitAllocation &&
    groupPayment.splitAllocation - numberOfCustomersJoinedInGroup;

  const canGuestJoin: boolean = numberOfAvailableSpacesLeft
    ? numberOfAvailableSpacesLeft - addedSortedCustomers.length > 0
    : false;

  useEffect(() => {
    // check if no added customers were added and customer queries have all finished loading
    if (addedSortedCustomers.length === 0 && !areCustomerQueriesLoading) {
      // show joiner overview modal instead of member list
      setModalStep(JoinerGroupModalStep.JOINER_OVERVIEW);
    }
  }, [addedSortedCustomers.length, areCustomerQueriesLoading, setModalStep]);

  const handleCustomerSelection = (customer: Customer) => {
    queryClient.setQueryData<Customer | null>(
      ['selectedCustomer'],
      customer.id === selectedCustomer?.id ? null : customer
    );
  };

  const handleContinueAsUser = (joinAsNewUser?: boolean) => {
    if (joinAsNewUser) {
      queryClient.setQueryData<Customer | null>(['selectedCustomer'], null);
    }

    setModalStep(JoinerGroupModalStep.JOINER_OVERVIEW);
  };

  const isEqualOrFixedGroupFull =
    !!groupPayment.splitAllocation &&
    numberOfCustomersJoinedInGroup === groupPayment.splitAllocation;

  if (isEqualOrFixedGroupFull) {
    return (
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Typography variant="subtitle1" color="grey.500" align="center">
          {t('joinGroupModal.components.equalMemberList.groupFull', {
            ns: 'modals',
          })}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{overflow: 'auto', maxHeight: '300px'}}>
        {addedSortedCustomers.map((customer) => (
          <EqualMemberListItem
            key={customer.id}
            groupPayment={groupPayment}
            customer={customer}
            isCustomerSelected={selectedCustomer?.id === customer.id}
            handleCustomerSelection={handleCustomerSelection}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => handleContinueAsUser()}
          disabled={!selectedCustomer}
        >
          {selectedCustomer
            ? t(
                'joinGroupModal.components.equalMemberList.button.customerJoin.withCustomerName',
                {firstName: selectedCustomer.firstName}
              )
            : t(
                'joinGroupModal.components.equalMemberList.button.customerJoin.withoutCustomerName'
              )}
        </Button>
      </Grid>
      {canGuestJoin && !isEqualOrFixedGroupFull && (
        <>
          <Grid item xs={12}>
            <Divider>
              <Typography fontSize={12} color="grey.600">
                {t('or', {ns: 'glossary'})}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => handleContinueAsUser(true)}
              fullWidth
            >
              {t('joinGroupModal.components.equalMemberList.button.guestJoin', {
                ns: 'modals',
              })}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EqualMemberList;
