import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, {FC, useMemo} from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {useTranslation} from 'react-i18next';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import Stack from '@mui/material/Stack';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import LocaleSelectButton from '@local/frontend/components/atoms/buttons/LocaleSelectButton';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {useSearchParams} from 'react-router-dom';
import useGetAllPaymentsFromGroup from '@local/frontend/hooks/payments/useGetAllPaymentsFromGroup';
import ModalHeader from '../ModalHeader';

interface DashboardSettingsModalProps {
  isOwner: boolean;
  customer: Customer;
  groupPayment: GroupPaymentRecord;
}

const DashboardSettingsModal: FC<
  React.PropsWithChildren<DashboardSettingsModalProps>
> = ({isOwner, customer, groupPayment}) => {
  const {openModal} = useCustomModals();
  const {t} = useTranslation(['modals']);

  const customerId = useCustomerId();
  const [searchParams] = useSearchParams();
  const onBehalfOfId = searchParams.get('on_behalf') ?? undefined;

  const handleLeave = () => {
    openModal(ModalName.LEAVE_GROUP, {
      isOwner,
      customer,
      groupPayment,
    });
  };

  const {payments} = useGetAllPaymentsFromGroup(groupPayment.id);

  const hasCompletedPayment = useMemo(
    () =>
      payments?.some(
        (payment) =>
          payment &&
          payment.status === 'COMPLETED' &&
          payment.customerId === customerId
      ),
    [payments]
  );

  const showLeaveButton = !hasCompletedPayment && !onBehalfOfId;

  return (
    <Stack spacing={2}>
      <ModalHeader
        title={t('dashboardSettingsModal.title', {ns: 'modals'})}
        modalName={ModalName.DASHBOARD_SETTINGS}
      />
      <List>
        <ListItem>
          <ListItemIcon sx={{color: 'primary.main'}}>
            <LanguageIcon />
          </ListItemIcon>
          <LocaleSelectButton />
        </ListItem>
        {showLeaveButton && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleLeave}>
              <ListItemIcon sx={{color: 'primary.main'}}>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('dashboardSettingsModal.leaveGroup', {ns: 'modals'})}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Stack>
  );
};

export default DashboardSettingsModal;
