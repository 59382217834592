import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/merchants/PublicConnectDTO';
import {RelevantCheckoutIdsMap} from '../@types';
import {
  findPaymentReadyGateway,
  FindPaymentReadyGatewayParams,
} from '../../../libs/api/connect.actions';

export const useFindPaymentReadyGateway = (
  findPaymentReadyGatewayParams: FindPaymentReadyGatewayParams
) =>
  useQuery<PublicConnectDTO>(['connectedGateways'], async () =>
    findPaymentReadyGateway({
      country: findPaymentReadyGatewayParams.country,
      currency: findPaymentReadyGatewayParams.currency,
    })
  );

export const useInitialiseRelevantCheckoutIdsMap = (
  ids: RelevantCheckoutIdsMap,
  queryOptions?: UseQueryOptions<RelevantCheckoutIdsMap | undefined>
) =>
  useQuery<RelevantCheckoutIdsMap | undefined>(
    ['ids'],
    () => ids,
    queryOptions
  );

export const useCheckoutIds = () => {
  const queryClient = useQueryClient();
  return useQuery<RelevantCheckoutIdsMap | undefined>(
    ['ids'],
    () => queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']),
    {
      refetchOnWindowFocus: false,
    }
  );
};
