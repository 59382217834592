import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {BiTransferAlt} from 'react-icons/bi';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CancelIcon from '@mui/icons-material/Cancel';
import {Collapse} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {useRemoveFromGroupPayment} from '@local/frontend/libs/trpc/trpc';
import {useGetGroupCustomerPayment} from '@local/frontend/hooks/payments/useGetGroupCustomerPayment';
import {getGroupPaymentCustomerJoinUrl} from '../../../../util';
import useCustomerShareAmount from '../../../../hooks/useCustomerShareAmount';
import {useGroupPaymentCustomerShares} from '../../../../hooks/queries/group-payments';
import {useProxyMutation} from '../../../../hooks/mutations/proxies';
import {
  useKickFromGroup,
  useUpdateGroupPayment,
} from '../../../../hooks/mutations/groups-payments';
import {useCurrentCustomerId} from '../../../../hooks/data/ids';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';
import {cancelPayment} from '../../../../libs/api/payment.actions';
import useCustomer from '../../../../hooks/data/customers';
import AnimatedDialog from '../../../molecules/dialogs/AnimatedDialog';
import RemoveCustomerDialog from '../../../molecules/dialogs/dashboard/RemoveCustomerDialog';
import {handleOnRemove} from '../helpers/SendReminder/handle-remove';
import ConfirmationDialogContent from '../../../molecules/dialogs/ConfirmationDialogContent';
import {handleKick} from '../helpers/handle-kick';
import {ConfirmationParams} from '../@types/ConfirmationParams';
import {handleRemindMember} from '../helpers/SendReminder/handle-remind-member';

const getDefaultParticipantActionsMenuStyles = (
  placement: 'left' | 'right' = 'right'
) => {
  const positionProps = placement === 'left' ? {left: 14} : {right: 14};

  return {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
      ...positionProps,
    },
  };
};

interface ParticipantActionsMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  groupPayment: GroupPaymentRecord;
  participant: Customer;
  canPayOnBehalf: boolean;
  onPayOnBehalf?: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    onBehalfOf: string
  ) => void;
  canCancelPayment: boolean;
  canKick: boolean;
  canRemind: boolean;
  canModifyBasket: boolean;
  placement?: 'left' | 'right';
}

const ParticipantActionsMenu: React.FC<
  React.PropsWithChildren<ParticipantActionsMenuProps>
> = ({
  anchorEl,
  setAnchorEl,
  groupPayment,
  participant,
  canPayOnBehalf,
  onPayOnBehalf,
  canCancelPayment,
  canKick,
  canRemind,
  canModifyBasket,
  placement,
}) => {
  const {t} = useTranslation(['dashboard', 'confirmation-dialog']);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {openModal} = useCustomModals();
  const enableSms = useFeatureFlagEnabled('enable-sms');
  const enableEmail = useFeatureFlagEnabled('enable-email');

  const currentCustomerId = useCurrentCustomerId();
  const customer = useCustomer(currentCustomerId);

  const [isReminderOptionsOpen, setIsReminderOptionsOpen] = useState(false);

  const [showCancelPaymentDialog, setShowCancelPaymentDialog] = useState(false);
  const [paymentIdToCancel, setPaymentIdToCancel] = useState<
    string | undefined
  >(undefined);

  const [showKickDialog, setShowKickDialog] = useState(false);
  const [isKickingAndReducing, setIsKickingAndReducing] = useState(false);

  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useState(false);

  const [cancelConfirmationParams, setCancelConfirmationParams] = useState<
    ConfirmationParams | undefined
  >(undefined);

  const kickFromGroupPaymentMutation = useKickFromGroup();
  const removeFromGroupPaymentMutation = useRemoveFromGroupPayment();
  const updateGroupPaymentMutation = useUpdateGroupPayment();

  const {customerPayment: payment} = useGetGroupCustomerPayment(
    groupPayment.id,
    participant.id
  );

  const remindCustomerMutation = useMutation(handleRemindMember);

  const createRedirectProxyMutation = useProxyMutation();

  const {data: groupPaymentCustomerSharesMap} = useGroupPaymentCustomerShares(
    groupPayment.id
  );

  const customerShareAmount = useCustomerShareAmount(
    participant.id,
    groupPaymentCustomerSharesMap
  );

  // check if participant is able to be reminded via SMS
  const canRemindViaSms = !!participant.phoneNumber && !!enableSms;
  const canRemindViaEmail = !!participant.email && !!enableEmail;

  const handleCloseCustomerOptionsMenu = (
    event?: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleRemindViaEmail = async (customerBeingReminded: Customer) => {
    if (!customerShareAmount) {
      throw new Error(
        t('body.components.participantActionsMenu.errors.noShareCannotRemind', {
          ns: 'dashboard',
        })
      );
    }

    const customerBeingRemindedInviteUrl =
      await createRedirectProxyMutation.mutateAsync({
        url: getGroupPaymentCustomerJoinUrl(groupPayment, participant.id),
      });

    remindCustomerMutation.mutate({
      queryClient,
      groupPayment,
      customer: customerBeingReminded,
      customerBeingRemindedInviteUrl,
      remindMethod: 'EMAIL',
      t,
    });
  };

  const handleRemindViaSms = async (customerBeingReminded: Customer) => {
    if (!customerShareAmount) {
      throw new Error(
        t('body.components.participantActionsMenu.errors.noShareCannotRemind', {
          ns: 'dashboard',
        })
      );
    }

    const customerBeingRemindedInviteUrl =
      await createRedirectProxyMutation.mutateAsync({
        url: getGroupPaymentCustomerJoinUrl(groupPayment, participant.id),
      });

    remindCustomerMutation.mutate({
      queryClient,
      groupPayment,
      customer: customerBeingReminded,
      customerBeingRemindedInviteUrl,
      remindMethod: 'SMS',
      t,
    });
  };

  const remindViaEmailMutation = useMutation(handleRemindViaEmail, {
    onSettled: () => {
      handleCloseCustomerOptionsMenu();
    },
  });

  const remindViaSmsMutation = useMutation(handleRemindViaSms, {
    onSettled: () => {
      handleCloseCustomerOptionsMenu();
    },
  });

  const cancelPaymentMutation = useMutation(cancelPayment, {
    onError: (e: AxiosError<DefaultResponseError>) => {
      const errorMessage = e.response?.data.detail;
      openNotification({
        message:
          errorMessage ??
          t(
            'body.components.participantActionsMenu.errors.failedToCancelPayment',
            {ns: 'dashboard'}
          ),
        severity: 'error',
      });
    },
    onSettled: () => {
      handleCloseCustomerOptionsMenu();
    },
  });

  const handleRemindSelection = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    customerBeingReminded: Customer
  ) => {
    e.stopPropagation();
    if (canRemindViaEmail && canRemindViaSms) {
      setIsReminderOptionsOpen(!isReminderOptionsOpen);
    } else if (canRemindViaEmail) {
      remindViaEmailMutation.mutate(customerBeingReminded);
    } else {
      remindViaSmsMutation.mutate(customerBeingReminded);
    }
  };

  const handleCancelPaymentSelection = (customerPaymentId: string) => {
    setPaymentIdToCancel(customerPaymentId);
    setShowCancelPaymentDialog(true);
  };

  const onModifyBasketSelection = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    openModal(
      ModalName.SBI_MODIFY_BASKET,
      {initialCustomer: participant},
      {
        canClose: true,
      }
    );

    handleCloseCustomerOptionsMenu(event);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="customer-options-menu"
        open={open}
        onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
          handleCloseCustomerOptionsMenu(e)
        }
        PaperProps={{
          elevation: 0,
          sx: getDefaultParticipantActionsMenuStyles(placement),
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {canModifyBasket && (
          <MenuItem onClick={(e) => onModifyBasketSelection(e)}>
            <ListItemIcon>
              <ShoppingBagIcon />
            </ListItemIcon>
            {t('body.components.participantActionsMenu.options.modifyBasket', {
              ns: 'dashboard',
            })}
          </MenuItem>
        )}
        {canPayOnBehalf && (
          <MenuItem
            onClick={
              onPayOnBehalf
                ? (e) => onPayOnBehalf(e, participant.id)
                : undefined
            }
          >
            <ListItemIcon>
              <BiTransferAlt size={22} />
            </ListItemIcon>
            {t('body.components.participantActionsMenu.options.pay', {
              ns: 'dashboard',
            })}
          </MenuItem>
        )}
        {canRemind && (canRemindViaEmail || canRemindViaSms) && (
          <MenuItem onClick={(e) => handleRemindSelection(e, participant)}>
            <ListItemIcon>
              <NotificationsIcon fontSize="small" />
            </ListItemIcon>
            {t('body.components.participantActionsMenu.options.remind', {
              ns: 'dashboard',
            })}
            {canRemindViaEmail && canRemindViaSms && (
              <ListItemIcon sx={{ml: 2, color: '#222'}}>
                <ArrowDropDownIcon />
              </ListItemIcon>
            )}
          </MenuItem>
        )}

        {canRemind && (canRemindViaEmail || canRemindViaSms) && (
          <Collapse
            in={isReminderOptionsOpen}
            timeout="auto"
            unmountOnExit
            sx={{width: '100%', pl: 4}}
          >
            {canRemindViaEmail && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  remindViaEmailMutation.mutate(participant);
                }}
              >
                <ListItemIcon>
                  <EmailIcon fontSize="small" />
                </ListItemIcon>
                {t(
                  'body.components.participantActionsMenu.options.remindViaEmail',
                  {ns: 'dashboard'}
                )}
              </MenuItem>
            )}
            {canRemindViaSms && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  remindViaSmsMutation.mutate(participant);
                }}
              >
                <ListItemIcon>
                  <SmsIcon fontSize="small" />
                </ListItemIcon>
                {t(
                  'body.components.participantActionsMenu.options.remindViaSms',
                  {ns: 'dashboard'}
                )}
              </MenuItem>
            )}
          </Collapse>
        )}
        {canKick && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setShowKickDialog(true);
            }}
          >
            <ListItemIcon>
              <PersonRemoveIcon fontSize="small" />
            </ListItemIcon>
            {t(
              'body.components.participantActionsMenu.options.removeGroupMember',
              {ns: 'dashboard'}
            )}
          </MenuItem>
        )}
        {canCancelPayment && payment && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleCancelPaymentSelection(payment.id);
            }}
          >
            <ListItemIcon>
              <CancelIcon fontSize="small" />
            </ListItemIcon>
            {t('body.components.participantActionsMenu.options.cancelPayment', {
              ns: 'dashboard',
            })}
          </MenuItem>
        )}
      </Menu>
      {/* @TODO: dialogs need to moved to a context provider like modals after pay on behalf as part of tech debt clean up */}
      {showKickDialog && (
        <AnimatedDialog
          open={showKickDialog}
          handleClose={() => setShowKickDialog(false)}
        >
          <RemoveCustomerDialog
            open={showKickDialog}
            groupPayment={groupPayment}
            customerBeingRemoved={participant.firstName}
            isKickLoading={
              !isKickingAndReducing &&
              (kickFromGroupPaymentMutation.isLoading ||
                updateGroupPaymentMutation.isLoading)
            }
            isKickAndUpdateLoading={
              isKickingAndReducing &&
              (kickFromGroupPaymentMutation.isLoading ||
                updateGroupPaymentMutation.isLoading)
            }
            handleReject={() => setShowKickDialog(false)}
            handleConfirm={(isDecreasingGroupSize?: boolean) => {
              if (
                isDecreasingGroupSize &&
                isDecreasingGroupSize !== isKickingAndReducing
              ) {
                setIsKickingAndReducing(isDecreasingGroupSize);
              }

              handleOnRemove(
                queryClient,
                groupPayment,
                updateGroupPaymentMutation,
                kickFromGroupPaymentMutation,
                removeFromGroupPaymentMutation,
                participant,
                setShowCancelConfirmationDialog,
                setCancelConfirmationParams,
                isDecreasingGroupSize,
                t
              );
            }}
          />
        </AnimatedDialog>
      )}
      {showCancelConfirmationDialog && (
        <AnimatedDialog
          open={showCancelConfirmationDialog}
          handleClose={() => setShowCancelConfirmationDialog(false)}
        >
          <ConfirmationDialogContent
            title={t('title', {ns: 'confirmation-dialog'})}
            buttonActions={{
              reject: t('button.reject', {ns: 'confirmation-dialog'}),
              accept: t('button.accept', {ns: 'confirmation-dialog'}),
            }}
            description={t(
              'body.components.participantActionsMenu.dialogs.updateGroupCancelConfirmation',
              {ns: 'dashboard'}
            )}
            handleReject={() => setShowCancelConfirmationDialog(false)}
            handleConfirm={() => {
              if (!cancelConfirmationParams) {
                throw new Error(
                  'Failed to update group. Cancel confirmation params are required'
                );
              }

              // handle confirmation and cancel necessary payments
              handleKick(
                cancelConfirmationParams.queryClient,
                cancelConfirmationParams.updateGroupPaymentMutation,
                cancelConfirmationParams.kickFromGroupPaymentMutation,
                cancelConfirmationParams.removeFromGroupPaymentMutation,
                cancelConfirmationParams.groupPayment,
                cancelConfirmationParams.customerToKick,
                cancelConfirmationParams.isDecreasingGroupSize,
                t
              );
            }}
            isConfirmationLoading={
              kickFromGroupPaymentMutation.isLoading ||
              updateGroupPaymentMutation.isLoading
            }
          />
        </AnimatedDialog>
      )}
      {showCancelPaymentDialog && (
        <AnimatedDialog
          open={showCancelPaymentDialog}
          handleClose={() => setShowCancelPaymentDialog(false)}
        >
          <ConfirmationDialogContent
            title={t('title', {ns: 'confirmation-dialog'})}
            buttonActions={{
              reject: t('button.reject', {ns: 'confirmation-dialog'}),
              accept: t('button.accept', {ns: 'confirmation-dialog'}),
            }}
            description={t(
              'body.components.participantActionsMenu.dialogs.cancelPaymentConfirmation',
              {ns: 'dashboard'}
            )}
            handleReject={() => setShowCancelPaymentDialog(false)}
            handleConfirm={() => {
              if (!customer || !paymentIdToCancel) {
                openNotification({
                  message: t(
                    'body.components.participantActionsMenu.errors.cancelPaymentConfirmationFailed',
                    {ns: 'dashboard'}
                  ),
                  severity: 'error',
                });

                throw new Error(
                  'Failed to cancel payment. No customer found to cancel payment'
                );
              }

              cancelPaymentMutation.mutate(
                {
                  paymentId: paymentIdToCancel,
                  groupPaymentId: groupPayment.id,
                  customerFirstName: customer.firstName,
                  participantFirstName: participant.firstName,
                },
                {
                  onSuccess: () => {
                    setShowCancelPaymentDialog(false);
                  },
                }
              );
            }}
            isConfirmationLoading={cancelPaymentMutation.isLoading}
          />
        </AnimatedDialog>
      )}
    </>
  );
};

export default ParticipantActionsMenu;
