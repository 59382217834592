import React, {FC, useMemo} from 'react';
import Typography from '@mui/material/Typography';
import Chip, {ChipProps} from '@mui/material/Chip';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useGetGroupCustomerPayment} from '@local/frontend/hooks/payments/useGetGroupCustomerPayment';
import {PaymentStatus} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {getPaymentStatusColor} from '../../../../util/converters/payment-status-color';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';

interface CustomerPaymentStatusPillProps extends ChipProps {
  groupPayment: GroupPaymentRecord;
  customerId: string | undefined;
}

const CustomerPaymentStatusPill: FC<
  React.PropsWithChildren<CustomerPaymentStatusPillProps>
> = ({customerId, groupPayment, ...props}) => {
  const {t} = useTranslation(['customer-payment-status-pill']);
  const queryClient = useQueryClient();

  const {isPaymentLoading, customerPayment: memberPayment} =
    useGetGroupCustomerPayment(groupPayment.id, customerId ?? '');

  const userStatus = useMemo(() => {
    switch (memberPayment?.status) {
      case PaymentStatus.APPROVED:
      case PaymentStatus.COMPLETED:
        // check if the customerId on the payment record is not the same as the specific customer in the participant list
        if (memberPayment.customerId !== customerId) {
          // this means that this customer was paid for by another customer
          const paidByCustomer = queryClient.getQueryData<Customer>([
            'customer',
            memberPayment.customerId,
          ]);
          return paidByCustomer
            ? t('status.paidOnBehalf.withCustomerName', {
                ns: 'customer-payment-status-pill',
                firstName: paidByCustomer?.firstName,
              })
            : t('status.paidOnBehalf.withoutCustomerName', {
                ns: 'customer-payment-status-pill',
              });
        }
        return t('status.paid', {ns: 'customer-payment-status-pill'});
      case PaymentStatus.FAILED:
        return t('status.failed', {ns: 'customer-payment-status-pill'});
      case PaymentStatus.CANCELLED:
        return t('status.cancelled', {ns: 'customer-payment-status-pill'});
      case PaymentStatus.PENDING:
        return t('status.pending', {ns: 'customer-payment-status-pill'});
      default:
        return customerId && groupPayment.memberIds.includes(customerId)
          ? t('status.joined', {ns: 'customer-payment-status-pill'})
          : t('status.notJoined', {ns: 'customer-payment-status-pill'});
    }
  }, [memberPayment?.status, groupPayment, customerId, t]);

  const statusColor = useMemo(
    () =>
      getPaymentStatusColor(
        memberPayment?.status ??
          (customerId && groupPayment.memberIds.includes(customerId)
            ? PaymentStatus.PENDING
            : PaymentStatus.CANCELLED)
      ),
    [customerId, groupPayment, memberPayment?.status]
  );

  return (
    <Chip
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: isPaymentLoading
          ? 'none'
          : statusColor.backgroundColor,
      }}
      size="small"
      label={
        isPaymentLoading ? (
          <Skeleton width={60} />
        ) : (
          <Typography
            variant="button"
            sx={{fontWeight: 400}}
            color={statusColor.textColor}
          >
            {userStatus}
          </Typography>
        )
      }
    />
  );
};

export default CustomerPaymentStatusPill;
