import {QueryClient} from '@tanstack/react-query';
import {io, Socket} from 'socket.io-client';
import {RelevantCheckoutIdsMap} from '../hooks/queries/@types';
import {groupPaymentKickUrl} from '../util';
import SocketEventData from './@types/SocketEventData';
import {GroupNotificationEvent} from './@types/GroupNotificationEvent';
import {openNotification} from '../helpers/notifications/notification-helpers';
import {BACKEND_CONFIG} from '../config/backend';

export default class GroupPaymentWebsocketClient {
  private readonly socket: Socket;

  constructor() {
    this.socket = io(BACKEND_CONFIG.HOST, {
      autoConnect: false,
    });
  }

  isConnected(): boolean {
    return this.socket.connected;
  }

  private registerEventHandlers(queryClient: QueryClient): void {
    this.socket.on(
      GroupNotificationEvent.GROUP_UPDATE,
      async (data: SocketEventData) => {
        const {
          groupPaymentId,
          event,
          customerId,
          merchantId,
          disableNotifications,
          message,
        } = data;
        // ensure all connected clients receive the following query invalidations on group updates
        await queryClient.invalidateQueries(['groupPayment', groupPaymentId]);

        await queryClient.invalidateQueries([
          'group-payment-shares',
          groupPaymentId,
        ]);

        await queryClient.invalidateQueries(['customer'], {exact: false});

        // check if a joined member has been kicked from the group
        if (event === GroupNotificationEvent.GROUP_KICK && customerId) {
          queryClient.removeQueries(['customer', customerId], {
            exact: true,
          });

          const currentCustomerId = queryClient.getQueryData<string>([
            'currentCustomerId',
          ]);

          if (currentCustomerId && currentCustomerId === customerId) {
            const kickUrl = groupPaymentKickUrl(
              groupPaymentId,
              customerId,
              merchantId
            );
            // redirect using window object as cant use hooks here or render jsx.
            window.location.href = kickUrl;
          }
        }

        // don't show notifications if disabled notifications is true
        if (!disableNotifications) {
          switch (event) {
            case GroupNotificationEvent.GROUP_UPDATE:
            case GroupNotificationEvent.GROUP_JOIN:
            case GroupNotificationEvent.GROUP_KICK:
              openNotification(queryClient, {
                message,
                severity: 'info',
              });
              break;

            case GroupNotificationEvent.GROUP_PAY:
              openNotification(queryClient, {
                message,
                severity: 'success',
              });
              break;

            case GroupNotificationEvent.GROUP_LEAVE:
              openNotification(queryClient, {
                message,
                severity: 'warning',
              });
              break;

            default:
              break;
          }
        }
      }
    );

    this.socket.on('CUSTOMERS:UPDATE', async (data) => {
      await queryClient.invalidateQueries(['customer', data.customerId]);
    });

    this.socket.on(GroupNotificationEvent.PAYMENT_CANCELLED, async (data) => {
      const {groupPaymentId, message} = data;

      await queryClient.invalidateQueries(['groupPayment', groupPaymentId]);

      queryClient.setQueryData(['notificationAlert'], {
        isShowing: true,
        message,
        severity: 'info',
        timeToShow: 5000,
      });
    });
  }

  connect(auth: RelevantCheckoutIdsMap, queryClient: QueryClient): void {
    this.socket.auth = auth;
    this.socket.connect();
    this.registerEventHandlers(queryClient);
  }

  disconnect(): void {
    this.socket.disconnect();
  }
}
