import {PaymentRecord as HandsInPaymentRecord} from '@handsin/api-node';

export enum PaymentStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface PaymentRecord extends Omit<HandsInPaymentRecord, 'status'> {
  onBehalfOf?: string;
  status: PaymentStatus;
  merchantId: string;
  multiCardId?: string;
}
