import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useLeaveGroupPayment} from '@local/frontend/hooks/mutations/groups-payments';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {useGetGroupCustomerPayment} from '@local/frontend/hooks/payments/useGetGroupCustomerPayment';
import useCustomer from '../../../hooks/data/customers';
import {formatFullName} from '../../../util/stringFormatters';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import NoticeAlert from '../../atoms/NoticeAlert';
import ModalHeader from '../modals/ModalHeader';

interface LeaveGroupModalProps {
  groupPayment: GroupPaymentRecord;
  isOwner: boolean;
  customer: Customer;
}

const LeaveGroupModal: FC<React.PropsWithChildren<LeaveGroupModalProps>> = ({
  groupPayment,
  isOwner,
  customer,
}) => {
  const {closeModal} = useCustomModals();
  const {t} = useTranslation(['leave-group-dialog']);
  const leaveGroupPaymentMutation = useLeaveGroupPayment();

  const amountOfJoinedParticipants = groupPayment.memberIds.length;

  const {customerPayment: memberPayment} = useGetGroupCustomerPayment(
    groupPayment.id,
    customer.id
  );

  // get the next customerId in memberIds as this customer will be the new owner if the current owner leaves.
  const newOwnerId = groupPayment.memberIds.at(1);
  const newOwner = useCustomer(newOwnerId);

  const handleLeaveGroupPayment = () => {
    leaveGroupPaymentMutation.mutate(
      {
        groupPaymentId: groupPayment.id,
        customer,
      },
      {
        onSuccess: () => {
          closeModal(ModalName.DASHBOARD_SETTINGS);
          closeModal(ModalName.LEAVE_GROUP);
        },
      }
    );
  };

  const renderContent = () => {
    const newOwnerName = newOwner
      ? formatFullName(newOwner.firstName, newOwner.lastName)
      : undefined;

    // check if the last person is leaving
    if (amountOfJoinedParticipants < 2 || !newOwnerName) {
      return (
        <NoticeAlert severity="error">
          {t('content.lastMember', {ns: 'leave-group-dialog'})}
        </NoticeAlert>
      );
    }

    // check if the owner is leaving
    if (isOwner) {
      return (
        <>
          <NoticeAlert severity="error">
            {t('content.ownerLeaving', {
              ns: 'leave-group-dialog',
              newOwnerName,
            })}
          </NoticeAlert>
          {memberPayment?.status === 'APPROVED' && (
            <NoticeAlert severity="warning">
              <Trans
                ns="leave-group-dialog"
                i18nKey="content.paymentWillCancel"
                components={{strong: <span style={{fontWeight: 500}} />}}
              />
            </NoticeAlert>
          )}
        </>
      );
    }

    // else a group member is leaving
    return (
      <>
        <NoticeAlert severity="error">
          {t('content.joinerLeaving', {ns: 'leave-group-dialog'})}
        </NoticeAlert>
        {memberPayment?.status === 'APPROVED' && (
          <NoticeAlert severity="warning">
            <Trans
              ns="leave-group-dialog"
              i18nKey="content.paymentWillCancel"
              components={{strong: <span style={{fontWeight: 500}} />}}
            />
          </NoticeAlert>
        )}
      </>
    );
  };

  return (
    <>
      <ModalHeader modalName={ModalName.LEAVE_GROUP} />
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" sx={{wordSpacing: 0}}>
          {t('title', {ns: 'leave-group-dialog'})}
        </Typography>
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal(ModalName.LEAVE_GROUP)}
          disabled={leaveGroupPaymentMutation.isLoading}
        >
          {amountOfJoinedParticipants < 2
            ? t('button.reject.text.keep', {ns: 'leave-group-dialog'})
            : t('button.reject.text.stay', {ns: 'leave-group-dialog'})}
        </Button>
        <LoadingButton
          color="error"
          onClick={handleLeaveGroupPayment}
          loading={leaveGroupPaymentMutation.isLoading}
          disabled={leaveGroupPaymentMutation.isLoading}
        >
          {amountOfJoinedParticipants < 2
            ? t('button.accept.text.cancel', {ns: 'leave-group-dialog'})
            : t('button.accept.text.leave', {ns: 'leave-group-dialog'})}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default LeaveGroupModal;
