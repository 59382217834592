import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React, {FC, useMemo, useState} from 'react';
import Typography from '@mui/material/Typography';
import {MoneyUtils} from '@handsin/money';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {useTheme} from '@mui/material/styles';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import ModalSpinner from '@local/frontend/components/atoms/ModalSpinner';
import {useCurrentCustomerId} from '@local/frontend/hooks/data/ids';
import NoticeAlert from '@local/frontend/components/atoms/NoticeAlert';
import {areObjectsEqual} from '@local/frontend/util';
import {useSplitByItemHelper} from '@local/frontend/hooks/queries/frontend/SBI/useSplitByItemHelper';
import useUpdateGroupPaymentMutationContext from '@local/frontend/hooks/useUpdateGroupPaymentMutationContext';
import useSbiContext from '@local/frontend/hooks/useSbiContext';
import {useNotification} from '@local/frontend/hooks/useNotification';
import useCustomer from '@local/frontend/hooks/data/customers';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {formatFullName} from '@local/frontend/util/stringFormatters';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import useGetCustomers from '@local/frontend/hooks/useGetCustomers';
import _ from 'lodash';
import useGetAllPaymentsFromGroup from '@local/frontend/hooks/payments/useGetAllPaymentsFromGroup';
import ModalHeader from '../../ModalHeader';
import SbiMergedFlowLineItemAssigner from '../merged-flow/SbiMergedFlowLineItemAssigner';
import GroupMemberAvatarSelector from '../../../ParticipantSelector.tsx/GroupMemberAvatarSelector';

interface SbiModifyBasketModalProps {
  initialCustomer: Customer | undefined;
}

const SbiModifyBasketModal: FC<
  React.PropsWithChildren<SbiModifyBasketModalProps>
> = ({initialCustomer}) => {
  const {t} = useTranslation(['sbi-modify-basket']);
  const theme = useTheme();
  const groupPayment = useCachedGroupPayment();
  const {updateGroupPaymentMutation} = useUpdateGroupPaymentMutationContext();
  const sbiHelper = useSplitByItemHelper(groupPayment);
  const sbiContext = useSbiContext();
  const {open: openNotification} = useNotification();

  const currentCustomerId = useCurrentCustomerId();
  const {getModal, closeModal} = useCustomModals();

  const modifyBasketModal = getModal(ModalName.SBI_MODIFY_BASKET);

  const allGroupMemberIds = groupPayment.memberIds.concat(
    groupPayment.invited ?? []
  );

  const {allGroupMembers, areCustomerQueriesLoading} =
    useGetCustomers(allGroupMemberIds);

  const {payments} = useGetAllPaymentsFromGroup(groupPayment.id);

  const customerIdsWithCompletedPayments =
    payments
      ?.filter((payment) => ['APPROVED', 'COMPLETED'].includes(payment.status))
      .map(
        (completedPaymentRecord) =>
          completedPaymentRecord.onBehalfOf ?? completedPaymentRecord.customerId
      )
      .filter(
        (maybeCustomerId): maybeCustomerId is string => !!maybeCustomerId
      ) ?? [];

  const isGroupOwnerViewing = currentCustomerId === groupPayment.ownerId;

  const [selectedGroupMemberId, setSelectedGroupMemberId] = useState<
    string | undefined
  >(initialCustomer?.id);

  const selectedCustomer = useCustomer(selectedGroupMemberId);
  const selectedCustomerAllocationCount = selectedCustomer
    ? sbiHelper.getAllocatedItemCount(selectedCustomer.id)
    : 0;

  // calculate and return true if a paid selected customer has changes to their basket
  const isUpdatingPaidCustomer = useMemo(() => {
    if (
      !groupPayment.itemAllocation ||
      !selectedCustomer ||
      !customerIdsWithCompletedPayments.includes(selectedCustomer.id)
    ) {
      return false;
    }
    const selectedCustomersLocalItemAllocation =
      sbiHelper.itemAlloc[selectedCustomer.id] ?? [];

    const selectedCustomersActualItemAllocation =
      groupPayment.itemAllocation[selectedCustomer.id] ?? [];

    const actualItemAllocationMap = _.keyBy(
      selectedCustomersActualItemAllocation,
      'itemId'
    );

    // handle 0 size array edge case here as .some won't iterate on empty array
    if (
      selectedCustomersLocalItemAllocation.length === 0 &&
      selectedCustomersActualItemAllocation.length > 0
    ) {
      return true;
    }

    return selectedCustomersLocalItemAllocation.some((localLineItem) => {
      const existingAllocatedItem =
        actualItemAllocationMap[localLineItem.itemId];

      // return true if the item is NOT current allocated to the paid customer or if the quantity has changed for them.
      return (
        !existingAllocatedItem ||
        localLineItem.quantity !== existingAllocatedItem.quantity
      );
    });
  }, [
    groupPayment.itemAllocation,
    selectedCustomer,
    customerIdsWithCompletedPayments,
  ]);

  const lineItemsToDisplay = sbiHelper.lineItems.filter((lineItem) => {
    const isAvaliable = Boolean(
      sbiHelper.getAvailableLineItem(lineItem.item.id)
    );

    if (selectedCustomer && isAvaliable) {
      return true;
    }

    return sbiHelper
      .getCustomerIdsForAllocatedLineItem(lineItem.item.id)
      .some(
        (customerIdAllocatedItem) =>
          !!selectedCustomer && customerIdAllocatedItem === selectedCustomer.id
      );
  });

  const isChanges = groupPayment.itemAllocation
    ? !areObjectsEqual(sbiHelper.itemAlloc, groupPayment.itemAllocation)
    : false;

  const customerNewShare =
    isChanges && selectedCustomer
      ? sbiHelper.calculateShareOf(selectedCustomer.id)
      : undefined;

  const handleGoBack = () => {
    closeModal(ModalName.SBI_MODIFY_BASKET);
  };

  const handleDiscardChanges = () => {
    if (groupPayment.itemAllocation) {
      sbiContext.setItemAlloc(groupPayment.itemAllocation);
    }
  };

  const handleUpdateBasket = () => {
    updateGroupPaymentMutation.mutate(
      {
        groupPayment,
        updateGroupPaymentParams: {
          splitType: SplitType.BY_ITEM,
          itemAllocation: sbiHelper.itemAlloc,
          disableNotifications: true,
        },
      },
      {
        onSuccess: () => {
          openNotification({
            message: t('alerts.success.updatedBasket', {
              ns: 'sbi-modify-basket',
            }),
            severity: 'success',
          });

          closeModal(ModalName.SBI_MODIFY_BASKET);
        },
      }
    );
  };

  if (areCustomerQueriesLoading) {
    return <ModalSpinner />;
  }

  if (!currentCustomerId) {
    return null;
  }

  const hasUnAssignedItems = sbiHelper.currentAvailableLineItems.length > 0;

  const groupMembersToAssignItemsTo = allGroupMembers.filter(
    (member) => !customerIdsWithCompletedPayments.includes(member.id)
  );

  return (
    <>
      <DialogTitle sx={{pt: 0, px: 0}}>
        <Stack spacing={1}>
          <Stack spacing={2}>
            <ModalHeader
              title={t('title', {
                ns: 'sbi-modify-basket',
                context: isGroupOwnerViewing ? 'owner' : 'joiner',
              })}
              subtitle={t('subtitle', {
                ns: 'sbi-modify-basket',
                context: isGroupOwnerViewing ? 'owner' : 'joiner',
              })}
              subtitleTypographyProps={{fontWeight: 400, fontSize: 14}}
              modalName={ModalName.SBI_MODIFY_BASKET}
              canClose={modifyBasketModal?.props?.canClose ?? false}
              onModalClose={() => {
                if (groupPayment.itemAllocation) {
                  sbiContext.setItemAlloc(groupPayment.itemAllocation);
                }
              }}
            />
            <Divider />
          </Stack>
          {[].length > 0 && (
            <Typography>
              {t('slider.title', {
                ns: 'sbi-modify-basket',
                context: selectedCustomer ? 'customer' : 'default',
                customerName: selectedCustomer
                  ? formatFullName(
                      selectedCustomer.firstName,
                      selectedCustomer.lastName
                    )
                  : '',
              })}
            </Typography>
          )}
          <GroupMemberAvatarSelector
            customers={groupMembersToAssignItemsTo}
            selectedGroupMemberId={selectedCustomer?.id}
            setSelectedGroupMemberId={setSelectedGroupMemberId}
          />
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          width: '100%',
          px: 1,
          pb: 0,
          maxHeight: '220px',
          minHeight:
            !selectedCustomer ||
            selectedCustomerAllocationCount > 0 ||
            sbiHelper.currentAvailableLineItems.length > 0
              ? '160px'
              : 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: 8,
          },

          '&::-webkit-scrollbar-track': {
            background: '#E2E2E2',
            borderRadius: 8,
          },
        }}
      >
        <SbiMergedFlowLineItemAssigner
          selectedCustomer={selectedCustomer}
          lineItemsToDisplay={lineItemsToDisplay}
        />
      </DialogContent>

      <Divider sx={{mt: 2}} />

      <DialogActions
        sx={{
          pt: 2,
          pb: 0,
          width: '100%',
        }}
      >
        <Stack spacing={1} sx={{width: '100%'}}>
          {selectedCustomer && customerNewShare && (
            <Stack
              direction="row"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{color: 'primary.main', fontWeight: 600, mr: 2}}
              >
                {t('newShare', {
                  ns: 'sbi-modify-basket',
                  customerName: selectedCustomer.firstName,
                })}
                :
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{color: 'primary.main', fontWeight: 600}}
              >
                {MoneyUtils.formatMoney(customerNewShare)}
              </Typography>
            </Stack>
          )}

          <Stack spacing={1}>
            {sbiHelper.currentAvailableLineItems.length > 0 && (
              <NoticeAlert
                message={t('alerts.warning.unAllocatedItemsAlert', {
                  ns: 'sbi-modify-basket',
                })}
                severity="warning"
              />
            )}
            {isUpdatingPaidCustomer && (
              <NoticeAlert
                message={t('alerts.errors.paymentWillBeCancelled', {
                  ns: 'sbi-modify-basket',
                  customerName: selectedCustomer
                    ? formatFullName(
                        selectedCustomer.firstName,
                        selectedCustomer.lastName
                      )
                    : '',
                })}
                severity="error"
              />
            )}
          </Stack>

          <Stack
            sx={{
              gap: 2,
              flexWrap: 'wrap',
              justifyContent: 'center',
              flexDirection: {xs: 'column', sm: 'row'},
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={isChanges ? handleDiscardChanges : handleGoBack}
              disabled={updateGroupPaymentMutation.isLoading}
              sx={{
                flex: {xs: '0 1 auto', sm: '0 1 calc(50% - 8px)'},
              }}
            >
              {isChanges
                ? t('buttons.discard', {ns: 'sbi-modify-basket'})
                : t('buttons.close', {ns: 'sbi-modify-basket'})}
            </Button>

            <LoadingButton
              fullWidth
              variant="contained"
              disabled={
                !isChanges ||
                hasUnAssignedItems ||
                updateGroupPaymentMutation.isLoading
              }
              onClick={handleUpdateBasket}
              loading={updateGroupPaymentMutation.isLoading}
              sx={{
                flex: {
                  xs: '0 1 auto',
                  sm: isGroupOwnerViewing ? '0 1 calc(50% - 8px)' : '0 1 auto',
                },
              }}
            >
              {t('buttons.updateBasket', {ns: 'sbi-modify-basket'})}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default SbiModifyBasketModal;
